import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Test from "../components/Test";
import { Login } from "../pages/Login/Login";
import UploadFile from "../pages/uploadFile/UploadFile";


const UserRoutes = () => {
    return (
        <Routes>
            {/* <Route path="/" element={<Test />} /> */}
            <Route path="/login" element={<Login />} />
            <Route path="/uploadfile" element={<UploadFile/>} />
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    );
    }

export default UserRoutes;