import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import useFileUpload from "react-use-file-upload";
import "./uploadFile.css";
import UploadLogoImg from "../../assets/images/upload-logo";
import CloseIcon from "@mui/icons-material/Close";
import HTTPService from "../../services/HTTPService";
import UrlConstants from "../../utils/constants/urlConstants";
import { getRoleLocal, getUserLocal } from "../../utils/common/common";
import { json, useNavigate } from "react-router-dom";
// import uploadLogo from "../../assets/images/upload-lo"

const UploadFile = (props) => {
  // const [uploadedFiles, setUploadedFiles] = useState();
  // const [uploadedProductCatalog, setUploadedProductCatalog] = useState()
 

  let navigate = useNavigate();

  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();

  const inputRef = useRef();

  

  useEffect(() => {
    let userRole = getRoleLocal();
    console.log("userRole", userRole);
    // setUserName(JSON.parse(localStorage.getItem('first_name')))
    // setOrgName(JSON.parse(localStorage.getItem('org_name')))

    // if (
    //   userRole === "fpc_admin" ||
    //   userRole === "fpc_chairman" ||
    //   userRole === "fpc_user"
    // ) {
    //   navigate("/uploadfile");
    // } else if (
    //   userRole === "super_user" ||
    //   userRole === "admin_user" ||
    //   userRole === "ri_user" ||
    //   userRole === "ri_admin" ||
    //   userRole === "flw"
    // ) {
    //   navigate("/login");
    // } else {
    //   navigate("/login");
    // }
  }, []);
  useEffect(()=>{
    clearAllFiles()
  },[props.clearFiles])
  console.log("filedsfdsf", files);

  return (
    <div className="upload-page-container">
      

      <div className="upload-file-title">
        <h1>{props.title}</h1>
      </div>
      <div
        //   css={CSS}
        className="upload-file-container"
      >
        {/* <p>Please use the form to your right to upload any file(s) of your choosing.</p> */}

        <div className="form-container">
          {/* Display the files to be uploaded */}

          {/* Provide a drop zone and an alternative button inside it to upload files. */}
          <div
            //   css={DropzoneCSS}
            onDragEnter={handleDragDropEvent}
            onDragOver={handleDragDropEvent}
            onDrop={(e) => {
              handleDragDropEvent(e);
              setFiles(e, "w");
            }}
            onClick={() => inputRef.current.click()}
            className="drag-and-drop-div"
          >
            <UploadLogoImg />
            <div className="drag-drop-placeholder-text">
              <h3>Drag and drop files here</h3>
              <p>Drop files here or click to browse thorough your system</p>
            </div>
            {/* <button onClick={() => inputRef.current.click()}>Or select files to upload</button> */}

            {/* Hide the crappy looking default HTML input */}
            <input
              id={props.title == 'Upload Amtech Farmer Data' ? 'uploade-file-input' : 'uploade-product-catalog-file-input'}
              ref={inputRef}
              type="file"
              // multiple
              style={{ display: "none" }}
              onChange={(e) => {
                setFiles(e, "w");
                props.handleUploadeFiles(e);
                inputRef.current.value = null;
              }}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
        </div>

        <div
          //  css={ListCSS}
          className="all-uploaded-files"
        >
          {files.map((file) => {
            let fileName = file.name.split(".");
            let fileType = fileName[fileName.length - 1];
            if (fileType === "xlsx" || fileType === "xls") {
            } else {
              removeFile(file.name);
              // window.alert(fileType + ' file is not allowed')
            }
            return (
              <div className="uploaded-file">
                <ul>
                  <li key={file.name}>
                    <div>
                      <span className="file-name-title">{file.name}</span>
                      <p className="file-size-title">
                        {(file.size / 1000).toFixed(1)} KB
                      </p>
                    </div>

                    <span onClick={() => removeFile(file.name)}>
                      <i className="fa fa-times" />
                      <CloseIcon />
                    </span>
                  </li>
                </ul>
              </div>
            );
          })}

          {/* {files.length > 0 && (
            <ul>
              <li>File types found: {fileTypes.join(', ')}</li>
              <li>Total Size: {totalSize}</li>
              <li>Total Bytes: {totalSizeInBytes}</li>
              <li className="clear-all">
                <button onClick={() => clearAllFiles()}>Clear All</button>
              </li>
            </ul>
          )} */}
        </div>
        <div className="drag-and-drop-submit-btn-div">
          <button onClick={props.handleSubmit}>Upload File</button>
        </div>
      </div>
    </div>
  );
};

export default UploadFile;
