import LocalStorageConstants from "../constants/constants";



  export const setTokenLocal = (token) => {
    localStorage.setItem(
      LocalStorageConstants.KEYS.JWTToken,
      JSON.stringify(token)
    );
  };
  
  export const getTokenLocal = () => {
    const tokenString = localStorage.getItem(LocalStorageConstants.KEYS.JWTToken);
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  export const setOrgId = (token) => {
    localStorage.setItem(
      LocalStorageConstants.KEYS.org_id,
      JSON.stringify(token)
    );
  };
  export const getOrgLocal = () => {
    const tokenString = localStorage.getItem(LocalStorageConstants.KEYS.org_id);
    const userToken = JSON.parse(tokenString);
    return userToken;
  };
  export const setUserIdLocal = (userId) => {
    localStorage.setItem(
      LocalStorageConstants.KEYS.userId,
      JSON.stringify(userId)
    );
  };
  export const getUserLocal = () => {
    const tokenString = localStorage.getItem(LocalStorageConstants.KEYS.userId);
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  export const setRoleLocal = (role) => {
    localStorage.setItem(LocalStorageConstants.KEYS.role, JSON.stringify(role));
  };

  export const getRoleLocal = () => {
    const roleString = localStorage.getItem(LocalStorageConstants.KEYS.role);
    const userRole = JSON.parse(roleString);
    return userRole;
  };

  export const setFirstName = (firstName) => {
    localStorage.setItem(LocalStorageConstants.KEYS.firstName, JSON.stringify(firstName));
  };

  export const getFirstName = () => {
    const firstNameString = localStorage.getItem(LocalStorageConstants.KEYS.firstName);
    const userName = JSON.parse(firstNameString);
    return userName;
  };


  export const flushLocalstorage = () => {
    Object.keys(LocalStorageConstants.KEYS).map((key,i)=>{
      console.log(key); 
      if (localStorage.getItem(key)) {
        localStorage.removeItem(key)
      }
    });
  }

  export const GetErrorKey = (e, keyList) => {
    var errorKeys = [];
    var errorMessages = [];
    for (var key of keyList) {
      if (
        e.response &&
        e.response.status === 400 &&
        e.response.data &&
        e.response.data[key]
      ) {
        errorKeys.push(key);
        errorMessages.push(e.response.data[key][0]);
      }
    }
    return [errorKeys, errorMessages];
  };