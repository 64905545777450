import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { TextField, Button, Typography, Snackbar, Alert } from "@mui/material";
import d2foImg from "../../assets/images/d2fo-login-img.jpg";
import './login.css'
import d2foLogo from "../../assets/images/d2fo-logo.png"
import UrlConstants from "../../utils/constants/urlConstants";
import HTTPService from "../../services/HTTPService";
import { flushLocalstorage, GetErrorKey, setOrgId, setRoleLocal, setTokenLocal, setUserIdLocal } from "../../utils/common/common";
import { Navigate, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

export const Login = () => {

    const [mobileNumber, setMobileNumber] = useState('')
    const [otp, setOtp] = useState('')
    const [enterOtp, setEnterOtp] = useState(false)
    const [email, setEmail]= useState('');
    const [emailError, setEmailError]= useState('');
    const [password, setPassword]= useState('');
    const [passwordError, setPasswordError]= useState('');

    // const [otpForUser, setOtpForUser] = useState("")
    const [loader, setLoader] = useState(false)
    const [snackbarState, setSnackbarState] = useState(false)
    const [snackbarSeverity, setSnackbarSeverity] = useState("")
    const [snackbarMessage, setSnackbarMessage] = useState("")

    
    const submitBtnClassName = email.match(/^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/)? 'submit-btn' : 'disable-btn'
    let vertical = 'top';
    let horizontal = 'right'

    const navigate = useNavigate();


    const handleNumber = (e) =>{
        let value = e.target.value
        // console.log("e.target.value",e.target.value.length)
        if(value.length<=10)  setMobileNumber(e.target.value)
        
    }

    const handleEmail = (value) =>{
      setEmailError("")
      setEmail(value)
    }
    const handlePassword = (value) =>{
      setPasswordError("")
      setPassword(value)
    }


    // const handleLoginSubmit = () =>{
    //     // let payload = {
    //     //     "phone" : mobileNumber
    //     // }
    //     // let url = UrlConstants.base_url + UrlConstants.get_login_otp_end_point

        

    //     setLoader(true)
    //     HTTPService("POST", url, payload)
        
    //     .then((res)=>{
    //         setLoader(false)
    //         setTokenLocal(res.data.user)
    //         // setOtpForUser(res.data?.otp)
    //         setEnterOtp(true)
            
            
    //     })
    //     .catch((err)=>{
    //         setLoader(false)
    //         console.log('err',err)
    //     })
    // }
    
    const handleLoginSubmit = () =>{
        // let url = UrlConstants.base_url + UrlConstants.verify_otp_end_point;
        
        // let payload = {
        //     'phone': mobileNumber,
        //     'otp' : otp
        // }



        let payload = {
          "email" : email,
          "password": password
        }
         let url = UrlConstants.base_url + UrlConstants.login

        setLoader(true)
        console.log("API CALL")
        HTTPService("POST", url, payload)
        .then((res)=>{
          console.log("res",res.data)
            setLoader(false)
            flushLocalstorage()
            setTokenLocal(res.data?.access_token)
            // setOrgId(res.data?.org?.id)
            // setUserIdLocal(res.data?.id)

            localStorage.setItem('first_name', JSON.stringify(res.data?.first_name))
            localStorage.setItem('last_name', JSON.stringify(res.data?.last_name))
            localStorage.setItem('org_id', JSON.stringify(res.data?.org?.id))
            localStorage.setItem('org_name', JSON.stringify(res.data?.org?.name))
            localStorage.setItem('fpc_id', JSON.stringify(res.data?.fpc?.id))
            localStorage.setItem('fpc_name', JSON.stringify(res.data?.fpc?.name))

            setRoleLocal(res?.data?.role_id)

            

            let userRole = res?.data?.role_id

            

            if(userRole=='1'){
                setSnackbarSeverity('success')
            setSnackbarMessage('Login success')
            setSnackbarState(true)
            handleCloseToast()
                navigate('/uploadfile')
              }
              else if(userRole==='super_user' || userRole==='admin_user' || userRole==='ri_user' || userRole==='ri_admin' || userRole==='flw'){
                setEnterOtp(false)
                setOtp('')
                setMobileNumber('')
                setSnackbarSeverity('success')
                setSnackbarMessage('Login success. There is nothing to show for your role')
            setSnackbarState(true)
            handleCloseToast()
              }else{
                setEnterOtp(false)
                setOtp('')
                setMobileNumber('')
                setSnackbarSeverity('success')
                setSnackbarMessage('Login success. There is nothing to show for your role')
              }
            // // navigate('/uploadfile')
            
        })
        .catch((err)=>{
            setLoader(false)
            console.log("err",err)
            if(err?.response?.status == 403){
              setEmailError(err?.response?.data?.email)
            }else if(err?.response?.status == 401){
              setPasswordError(err?.response?.data?.password);
            }else{
            var returnValues = GetErrorKey(err, Object.keys(payload));
            console.log("returnValues",returnValues)
            var errorKeys = returnValues[0];
            var errorMessages = returnValues[1];
            if (errorKeys.length > 0) {
                for (var i = 0; i < errorKeys.length; i++) {
                  console.log("error in switch case",errorMessages[i])
                    switch (errorKeys[i]) {
                        case "email":
                            setEmailError(errorMessages[i]);
                            break;
                        case "password":
                            setPasswordError(errorMessages[i]);
                            break;
                        default:
                            
                              setSnackbarSeverity("error")
                              setSnackbarMessage("Something went wrong!")
                            
                            break;
                    }
                }
            } else {
            setSnackbarSeverity('error')
            setSnackbarMessage('Something went wrong!')
            setSnackbarState(true)
            handleCloseToast()
            console.log("err",err)
            }
          }
        })
    }
    
    // useEffect(()=>{
    //     let userRole = getUserLocal();
    //     console.log('userRole',userRole)
    
    //     if(userRole==='fpc_admin' || userRole==='fpc_chairman' || userRole==='fpc_user'){
    //       navigate('/uploadfile')
    //     }
    //     else if(userRole==='super_user' || userRole==='admin_user' || userRole==='ri_user' || userRole==='ri_admin' || userRole==='flw'){
    //       navigate('/login')
    //     }
    // },[])

    const handleCloseToast = () => {
      setTimeout(() => {
        setSnackbarState(false);
      }, 5000);
    };

    


    const handleNumberSubmit = () =>{
        setEnterOtp(true)
    }


    // console.log('enterOtp',enterOtp)

    
  return (
    <>
    {
        loader ?  <Loader/>  : null
    }
    {/* <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={snackbarState}
        onClose={handleSnackbarClose}
        message="I love snacks"
        key={vertical + horizontal}
      > */}
        <Snackbar
         anchorOrigin={{ vertical, horizontal }}
         open={snackbarState}
   sx={{ width: '35%' }}
          >
  <Alert
         autoHideDuration={6000}
    //  onClose={handleSnackbarClose} 
   severity={snackbarSeverity} 
   sx={{ width: '200%' }}>
    {snackbarMessage}
  </Alert>
  
</Snackbar>
      <div className="center_keeping_conatiner">
        <Row>
          <Col xs={7} sm={7} md={7} lg={7}>
            <div>
              <img className='home-img-class' src={d2foImg} alt="login" />
            </div>
          </Col>
          <Col xs={5} sm={5} md={5} lg={5}>
            <Row className='homepage-logo'>
                <img src={d2foLogo} alt="D2FO LOGO" />
            </Row>
            <Row className='login-container'>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Col className='login-heading'>
                <Typography  variant="h4">Login</Typography>
                </Col>
                
                <Col xs={12} sm={12} md={12} lg={12}>
                    {/* {
                        enterOtp ?  (<>
                    <TextField 
                    className="login-input-field"
                  required
                  id="outlined-required"
                  label="Enter OTP"
                  type='number'
                  // helperText={otpForUser ? `Your OTP is : ${otpForUser}` : ""}
                  value={otp}
                  onChange={(e)=> otp.length<7 ? setOtp(e.target.value) : null}
                //   placeholder="Enter Mobile Number"
                />
                </>) 
                :
                (<>
                    <TextField 
                className="login-input-field"
              required
              id="outlined-required"
              label="Enter Mobile Number"
              type='number'
              value={mobileNumber}
              
              onChange={(e)=>  handleNumber(e)}
            //   placeholder="Enter Mobile Number"
            />
            </>)
                    } */}
                      <TextField 
                className="login-input-field"
              required
              id="outlined-required"
              label="Email"
              type='email'
              value={email}
              error={emailError}
              helperText={emailError? emailError : null}
              onChange={(e)=>  handleEmail(e.target.value)}
              // placeholder="Email"
            />
              <TextField 
                className="login-input-field"
              required
              id="outlined-required"
              label="password"
              type='password'
              value={password}
              error={passwordError}
              helperText={passwordError? passwordError : null}
              onChange={(e)=>  handlePassword(e.target.value)}
              // placeholder="password"
            />
                
                </Col>
                
              </Col>
              <Row>
              <div>
               {/* {
                enterOtp ? (
                    <>
                    <Button
                  variant="contained"
                  disabled={otp.length===6 ? false : true}
                  type="submit"
                  className={submitBtnClassName}
                  onClick={handleVerifyOtpBtn}
                >
                  Verify OTP
                </Button>
                    </>
                )
                :
                ( */}
                    {/* )
                   } */}
                    <>
                    <Button
                  variant="contained"
                  disabled={email.match(/^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/)? false : true}
                  type="submit"
                  className={submitBtnClassName}
                  onClick={handleLoginSubmit}
                >
                  Submit
                </Button>
                    </>
                
                {/* )} */}
              </div>
            </Row>
            </Row>

           
            {/* <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ marginTop: "136px" }}
              >
                <img
                  // style={useStyles.lastImage}
                  className="image"
                  // src={dglogo}
                  alt="digitalgreen"
                />
              </Col>
            </Row> */}

            {/* </div> */}
          </Col>
        </Row>
      </div>
    </>
  );
};
