
import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import UserRoutes from './routes/Routes';
import Test from './components/Test';
import UploadFile from './pages/uploadFile/UploadFile';

function App() {
  return (
    <>
        <BrowserRouter>
        <Routes>
          <Route path="*" element={<UserRoutes/>} />
        </Routes>
        </BrowserRouter>
        {/* <UploadFile/> */}
        
    </>
  );
}

export default App;
