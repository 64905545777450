// a test component


const Test = () =>{

    return (<>
    <h1>Test</h1>
    </>)
}

export default Test